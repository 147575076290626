import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const patientsServicesSlice = createSlice({
    name: 'patientsService',
    initialState: {
        getAll: { ...initialState },
        getAllCount: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState }
    },
    reducers: {
        createPatientsServiceStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createPatientsServiceSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createPatientsServiceFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        createPatientsServiceReset(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = false;
        },
        getAllPatientsServicesStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllPatientsServicesSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllPatientsServicesFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllPatientsServicesReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllPatientsServicesCountStart(state) {
            state.getAllCount.data = null;
            state.getAllCount.error = false;
            state.getAllCount.errorMessage = null;
            state.getAllCount.loading = true;
            state.getAllCount.success = false;
        },
        getAllPatientsServicesCountSuccess(state, action) {
            state.getAllCount.data = action.payload;
            state.getAllCount.error = false;
            state.getAllCount.errorMessage = null;
            state.getAllCount.loading = false;
            state.getAllCount.success = true;
        },
        getAllPatientsServicesCountFail(state, action) {
            state.getAllCount.data = null;
            state.getAllCount.errorMessage = action.payload;
            state.getAllCount.error = true;
            state.getAllCount.loading = false;
            state.getAllCount.success = false;
        },
        getAllPatientsServicesCountReset(state) {
            state.getAllCount.data = null;
            state.getAllCount.error = false;
            state.getAllCount.errorMessage = null;
            state.getAllCount.loading = false;
            state.getAllCount.success = false;
        },
        getOnePatientsServiceStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOnePatientsServiceSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOnePatientsServiceFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOnePatientsServiceReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        updatePatientsServiceStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updatePatientsServiceSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updatePatientsServiceFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updatePatientsServiceReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        },
        deletePatientsServiceStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deletePatientsServiceSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deletePatientsServiceFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        }
    }
});

export const {
    createPatientsServiceFail,
    createPatientsServiceStart,
    createPatientsServiceSuccess,
    createPatientsServiceReset,
    getAllPatientsServicesFail,
    getAllPatientsServicesReset,
    getAllPatientsServicesSuccess,
    getAllPatientsServicesStart,
    getAllPatientsServicesCountFail,
    getAllPatientsServicesCountReset,
    getAllPatientsServicesCountSuccess,
    getAllPatientsServicesCountStart,
    updatePatientsServiceStart,
    updatePatientsServiceReset,
    updatePatientsServiceFail,
    updatePatientsServiceSuccess,
    deletePatientsServiceFail,
    deletePatientsServiceSuccess,
    getOnePatientsServiceFail,
    getOnePatientsServiceReset,
    getOnePatientsServiceSuccess,
    getOnePatientsServiceStart,
    deletePatientsServiceStart
} = patientsServicesSlice.actions;

export const createPatientsService = ({ serviceData, recall }) => async (dispatch) => {
    try {
        dispatch(createPatientsServiceStart());
        const response = await APIClient.post('/patient-services', serviceData);
        if (response && response.data && response.data.success) {
            dispatch(createPatientsServiceSuccess(response.data));
            if (recall) recall();
            dispatch(addNotification({ severity: 'success', message: t('alerts.serviceCreateSuccess') }));
        } else {
            dispatch(createPatientsServiceFail(t('alerts.serviceCreateFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createPatientsServiceFail);
    }
};

export const getAllPatientsServices = (data) => async (dispatch) => {
    try {
        dispatch(getAllPatientsServicesStart());
        const response = await APIClient.get('/patient-services', {
            params: { patientId: data.patientId, pagination: data.pagination, medicalRecordId: data.medicalRecordId }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllPatientsServicesSuccess(response.data));
        } else {
            dispatch(getAllPatientsServicesFail(t('alerts.serviceFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllPatientsServicesFail);
    }
};

export const getOnePatientsService = (patientServiceId) => async (dispatch) => {
    try {
        dispatch(getOnePatientsServiceStart());
        const response = await APIClient.get(`/patient-services/${patientServiceId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOnePatientsServiceSuccess(response.data));
        } else {
            dispatch(getOnePatientsServiceFail(t('alerts.serviceFetchOneError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOnePatientsServiceFail);
    }
};

export const updatePatientsService = ({ serviceId, serviceData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(updatePatientsServiceStart());
        const response = await APIClient.patch(`/patient-services/${serviceId}`, serviceData);
        if (response && response.data && response.data.success) {
            dispatch(updatePatientsServiceSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.serviceUpdateSuccess') }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(updatePatientsServiceFail(t('alerts.serviceUpdateFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updatePatientsServiceFail);
    }
};

export const deletePatientsService = (ids, recall) => async (dispatch) => {
    try {
        const response = await APIClient.delete(`/patient-services`, { data: { deleteIds: ids.ids } });
        if (response.data && response.data && response.data.success) {
            dispatch(deletePatientsServiceSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.serviceDeleteSuccess') }));
            recall.recall();
        } else {
            dispatch(deletePatientsServiceFail(t('alerts.serviceDeleteFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.serviceDeleteFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deletePatientsServiceFail);
    }
};

export const getAllPatientsServiceCount = (data) => async (dispatch) => {
    try {
        dispatch(getAllPatientsServicesCountStart());
        const response = await APIClient.get('/statistics/patient-services/count', {
            params: { year: data.year, serviceName: data.serviceName, language: data.language, day: data.day }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllPatientsServicesCountSuccess(response.data));
        } else if (response && response.data && response.data.errors) {
            dispatch(getAllPatientsServicesCountFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: t('alerts.monthlyPatientsFetchError') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllPatientsServicesCountFail);
    }
};

export default patientsServicesSlice.reducer;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translationEN.json';
import translationSQ from './sq/translationSQ.json';

const resources = {
    sq: {
        translation: translationSQ
    },
    en: {
        translation: translationEN
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || 'sq',
    keySeparator: '.',
    interpolation: {
        escapeValue: false
    }
});

i18n.on('languageChanged', () => {
    window.location.reload();
});

export default i18n;

import { useEffect, useState } from 'react';
import logo from '../assets/images/login/logo-transparent-svg.svg';
import { useSelector } from 'react-redux';
import Loader from './Loader';

const Logo = () => {
    const currentUser = useSelector((state) => state.me.getMe);
    const [currentUserData, setCurrentUserData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentUser) {
            switch (true) {
                case currentUser.loading:
                    setLoading(true);
                    break;
                case currentUser.success:
                    setCurrentUserData(currentUser?.data);
                    setLoading(false);
                    break;
                case currentUser.error:
                    setLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [currentUser]);

    return (
        <div
            style={{
                height: 50
            }}
        >
            {!loading ? (
                <img
                    src={
                        currentUserData?.role !== 'SUPERADMIN'
                            ? currentUserData?.clinic?.landingPage?.length !== 0
                                ? currentUserData?.clinic?.landingPage[0]?.clinicLogo !== null
                                    ? currentUserData?.clinic?.landingPage[0]?.clinicLogo
                                    : currentUserData?.clinic?.landingPage[1]?.clinicLogo !== null
                                    ? logo
                                    : logo
                                : logo
                            : logo
                    }
                    alt="CrackHealth"
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'contain' }}
                />
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default Logo;

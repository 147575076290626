import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { gridSpacing } from 'store/constant';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { LocationOnOutlined, MailOutline, PhoneAndroidOutlined, ScheduleOutlined } from '@mui/icons-material';

const DefaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

const ContactPage = ({ clinicData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const landingPageData = clinicData?.landingPage?.find((page) => page.language === currentLanguage) || {};
    const ll = landingPageData.ll || [42.6629, 21.1655];
    const defaultCenter = {
        lat: ll[0],
        lng: ll[1]
    };

    const { streetAddress, city, country } = clinicData.address || {};

    return (
        <>
            <Divider />
            <Grid
                container
                spacing={isMobile ? 2 : gridSpacing}
                sx={{
                    flexDirection: isMobile ? 'column-reverse' : 'row'
                }}
                pb={0}
                mb={0}
            >
                <Grid item xs={12} md={6}>
                    <MapContainer
                        center={defaultCenter}
                        zoom={13}
                        style={{
                            width: '100%',
                            height: isMobile ? '80px' : '400px'
                        }}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={defaultCenter} />
                    </MapContainer>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    alignItems="center"
                    sx={{
                        paddingTop: isMobile ? 3 : 0,
                        paddingBottom: isMobile ? 3 : 0
                    }}
                >
                    <Grid container spacing={2} sx={{ pl: isMobile ? 2 : 4, pt: isMobile ? 3 : 0 }}>
                        <Grid item xs={12} pb={2}>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: 500,
                                    fontSize: isMobile ? '20px' : '28px',
                                    lineHeight: isMobile ? '26px' : '34px',
                                    paddingY: isMobile ? 2 : 0
                                }}
                                component="div"
                            >
                                {landingPageData?.header4 || t('landing_page.get_in_touch')}
                            </Typography>
                            <Divider
                                sx={{
                                    width: '80px',
                                    height: '2px',
                                    backgroundColor: theme.palette.secondary.main,
                                    marginTop: '8px'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} container alignItems="center" spacing={1}>
                            <Grid item>
                                <LocationOnOutlined
                                    sx={{
                                        width: isMobile ? '30px' : '30px',
                                        height: isMobile ? '30px' : '30px',
                                        marginRight: 2,
                                        color: '#212121'
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ ml: 2 }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '28px',
                                        color: '#212121'
                                    }}
                                >
                                    {streetAddress}, {city}, {country}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container alignItems="center" spacing={1}>
                            <Grid item>
                                <MailOutline
                                    sx={{
                                        width: isMobile ? '30px' : '30px',
                                        height: isMobile ? '30px' : '30px',
                                        marginRight: 2,
                                        color: '#212121'
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ ml: 2 }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '28px',
                                        color: '#212121'
                                    }}
                                >
                                    <Link href={`mailto:${clinicData.contactEmail}`} color="inherit">
                                        {clinicData.contactEmail}
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container alignItems="center" spacing={1}>
                            <Grid item>
                                <PhoneAndroidOutlined
                                    sx={{
                                        width: isMobile ? '30px' : '30px',
                                        height: isMobile ? '30px' : '30px',
                                        marginRight: 2,
                                        color: '#212121'
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ ml: 2 }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '28px',
                                        color: '#212121'
                                    }}
                                >
                                    {clinicData.number}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container alignItems="center" spacing={1}>
                            <Grid item>
                                <ScheduleOutlined
                                    sx={{
                                        width: isMobile ? '30px' : '30px',
                                        height: isMobile ? '30px' : '30px',
                                        marginRight: 2,
                                        color: '#212121'
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ ml: 2 }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '28px',
                                        color: '#212121'
                                    }}
                                >
                                    {t('landing_page.weekdays')}: {landingPageData.weekdaySchedule}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '28px',
                                        color: '#212121'
                                    }}
                                >
                                    {t('landing_page.weekends')}: {landingPageData.weekendSchedule}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ContactPage;

import { useTheme, styled } from '@mui/material/styles';
import { Container, Divider, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// assets
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { WhatsApp } from '@mui/icons-material';
import logo from 'assets/images/login/logo-transparent-svg.svg';

// styles
const FooterWrapper = styled('div')(({ theme }) => ({
    padding: '35px 0',
    background: theme.palette.grey[50],
    color: '#333',
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}));

const FooterLink = styled(Link)(({ theme }) => ({
    color: '#212121',
    textDecoration: 'none',
    fontSize: '16px',
    margin: '0 15px',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline'
    }
}));

const SocialLink = styled(Link)({
    color: '#212121',
    margin: '0 10px',
    '& svg': {
        fontSize: '1.2rem',
        width: '35px',
        height: '35px'
    },
    '&:hover': {
        color: '#000'
    }
});

const FooterSubWrapper = styled('div')(({ theme }) => ({
    padding: '20px 0',
    background: theme.palette.grey[50],
    textAlign: 'center',
    color: theme.palette.text.primary
}));

const Footer = ({ clinicData, scrollToSection, sections }) => {
    const { t } = useTranslation(); // Initialize translation hook
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Only show links on medium screens and up

    // Extract the links based on the current language
    const landingData = clinicData?.landingPage?.find((page) => page.language === 'en'); // or 'sq' based on the active language

    // Use default links for Facebook and Instagram
    const facebookLink = landingData?.facebook || 'https://www.facebook.com';
    const instagramLink = landingData?.instagram || 'https://www.instagram.com';

    return (
        <>
            <FooterWrapper>
                <Container>
                    <Grid container direction="column" alignItems="center" spacing={5}>
                        <Grid item>
                            <img src={logo} alt="Medica" height="70px" />
                        </Grid>
                        {isDesktop && ( // Render links only if screen size is medium or larger
                            <Grid item>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item>
                                        <FooterLink onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                            {t('landing_page.home')}
                                        </FooterLink>
                                    </Grid>
                                    <Grid item>
                                        <FooterLink onClick={() => scrollToSection(sections.servicesRef)}>
                                            {t('landing_page.services')}
                                        </FooterLink>
                                    </Grid>
                                    <Grid item>
                                        <FooterLink onClick={() => scrollToSection(sections.staffRef)}>
                                            {t('landing_page.staff')}
                                        </FooterLink>
                                    </Grid>
                                    <Grid item>
                                        <FooterLink onClick={() => scrollToSection(sections.contactRef)}>
                                            {t('landing_page.contact')}
                                        </FooterLink>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item>
                                    <SocialLink href={facebookLink} target="_blank">
                                        <FacebookIcon />
                                    </SocialLink>
                                </Grid>
                                <Grid item>
                                    <SocialLink href={instagramLink} target="_blank">
                                        <InstagramIcon />
                                    </SocialLink>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </FooterWrapper>
            <FooterSubWrapper>
                <Divider />
                <Typography variant="body2" color="inherit" mt={1}>
                    {t('landing_page.copyright')}
                </Typography>
            </FooterSubWrapper>
        </>
    );
};

export default Footer;

import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppBar as MuiAppBar, Box, Button, Container, Drawer, Stack, Toolbar, useScrollTrigger, useMediaQuery } from '@mui/material';
import logo from 'assets/images/login/logo-transparent-svg.svg';
import PhoneIcon from '@mui/icons-material/Phone';
import LocalizationSection from 'layout/MainLayout/Header/LocalizationSection';

function ElevationScroll({ children, window }) {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window
    });
    const darkBorder = theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200];

    return cloneElement(children, {
        elevation: trigger ? 0 : 0,
        style: {
            backgroundColor: theme.palette.background.default,
            borderBottom: trigger ? 'none' : '1px solid',
            borderColor: trigger ? '' : darkBorder,
            color: theme.palette.text.dark
        }
    });
}

const AppBar = ({ clinicData, scrollToSection, sections, ...others }) => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();
    const [drawerToggle, setDrawerToggle] = useState(false);
    const currentLanguage = i18n.language;

    const landingData = clinicData?.landingPage?.find((page) => page.language === currentLanguage) || {};
    const drawerToggler = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };

    return (
        <Container>
            <ElevationScroll {...others}>
                <MuiAppBar>
                    <Container maxWidth="xl">
                        <Toolbar sx={{ position: 'relative', justifyContent: 'space-between' }}>
                            {/* Logo on the left */}
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
                                <img
                                    src={landingData.clinicLogo || logo}
                                    alt="Logo"
                                    style={{
                                        height: 50,
                                        maxWidth: '100%'
                                    }}
                                    sx={{
                                        height: { xs: 20, sm: 50 } // Set smaller height on mobile (xs) and default on larger screens (sm+)
                                    }}
                                />
                            </Box>

                            {/* Centered Navigation links */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    display: { xs: 'none', sm: 'none', md: 'block' }
                                }}
                            >
                                <Stack direction="row" spacing={4} alignItems="center">
                                    <Button
                                        color="inherit"
                                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        sx={{ fontSize: '16px', fontWeight: 400 }}
                                    >
                                        {t('landing_page.home')}
                                    </Button>
                                    <Button
                                        color="inherit"
                                        onClick={() => scrollToSection(sections.servicesRef)}
                                        sx={{ fontSize: '16px', fontWeight: 400 }}
                                    >
                                        {t('landing_page.services')}
                                    </Button>
                                    <Button
                                        color="inherit"
                                        onClick={() => scrollToSection(sections.staffRef)}
                                        sx={{ fontSize: '16px', fontWeight: 400 }}
                                    >
                                        {t('landing_page.staff')}
                                    </Button>
                                    <Button
                                        color="inherit"
                                        onClick={() => scrollToSection(sections.contactRef)}
                                        sx={{ fontSize: '16px', fontWeight: 400 }}
                                    >
                                        {t('landing_page.contact')}
                                    </Button>
                                </Stack>
                            </Box>

                            {/* Phone button aligned to the right */}
                            <Box sx={{ display: { sm: 'flex' }, alignItems: 'center' }}>
                                <Button
                                    startIcon={<PhoneIcon sx={{ fontSize: matchesXs ? '16px' : '20px' }} />} // Adjust icon size
                                    href={`tel:${clinicData.number}`}
                                    sx={{
                                        height: matchesXs ? '40px' : '48px', // Adjust height based on screen size
                                        color: theme.palette.secondary.main,
                                        textTransform: 'none',
                                        padding: matchesXs ? '6px 10px' : '8px 16px', // Adjust padding for smaller screens
                                        border: `1.5px solid ${theme.palette.secondary.main}`, // Border adjustment for smaller screens
                                        borderRadius: '4px',
                                        fontSize: matchesXs ? '12px' : '16px', // Smaller font size for mobile
                                        fontWeight: '400',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px',
                                        justifyContent: 'center',
                                        backgroundColor: 'transparent',
                                        transition: 'all 0.3s ease', // Smooth transition for scaling effect
                                        '&:hover': {
                                            backgroundColor: '#f1f1f1'
                                        },
                                        '&:active': {
                                            transform: 'scale(0.95)' // Scale down when clicked
                                        }
                                    }}
                                >
                                    {clinicData.number}
                                </Button>
                            </Box>
                            <Box sx={{ display: { sm: 'flex' }, alignItems: 'center' }}>
                                <LocalizationSection />
                            </Box>
                        </Toolbar>
                    </Container>
                </MuiAppBar>
            </ElevationScroll>
        </Container>
    );
};

export default AppBar;

import React from 'react';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const columnsData = [
    { id: 'personalnumber', label: 'dashboard.recentPatients_personalNumber', minWidth: 100 },
    { id: 'name', label: 'dashboard.recentPatients_name', minWidth: 100 },
    { id: 'phone', label: 'dashboard.recentPatients_phone', minWidth: 120 },
    { id: 'email', label: 'dashboard.recentPatients_email', minWidth: 120 },
    { id: 'date', label: 'dashboard.recentPatients_birthdate', minWidth: 120 }
];

const RecentPatientTable = ({ data }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid
            mt={1}
            sx={{
                backgroundColor: 'white',
                boxShadow: theme.customShadows.z1,
                borderRadius: '10px',
                ml: '10px',
                p: 1
            }}
            xs={12}
        >
            <Typography variant="h6" p={2}>
                {t('dashboard.recentPatients_header')}
            </Typography>
            <TableContainer xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columnsData.map((column) => (
                                <TableCell key={column.id} style={{ minWidth: column.minWidth, fontSize: '12px' }}>
                                    {t(column.label)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((doctor) => (
                            <TableRow key={doctor.id}>
                                {columnsData.map((column) => {
                                    const cellContent = doctor[column.id];
                                    return (
                                        <TableCell key={column.id} style={{ minWidth: column.minWidth, fontSize: '12px', padding: '10px' }}>
                                            {cellContent}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default RecentPatientTable;

import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import axios from 'axios';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const clinicSlice = createSlice({
    name: 'reports',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState }
    },
    reducers: {
        createReportStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createReportSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createReportFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        createReportReset(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = false;
        },
        getAllReportsStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllReportsSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllReportsFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllReportsReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneReportStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneReportSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneReportFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOneReportReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        updateReportStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateReportSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateReportFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updateReportReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        },
        deleteReportStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteReportSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteReportFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        }
    }
});

export const {
    createReportStart,
    createReportSuccess,
    createReportFail,
    createReportReset,
    getAllReportsStart,
    getAllReportsSuccess,
    getAllReportsFail,
    getAllReportsReset,
    updateReportFail,
    updateReporteset,
    updateReportSuccess,
    updateReportStart,
    deleteReportSuccess,
    deleteReportFail,
    getOneReportFail,
    getOneReportStart,
    getOneReportReset,
    getOneReportSuccess
} = clinicSlice.actions;

export const createReport = ({ reportData, recallReports, recall, setShowListOfReports }) => async (dispatch) => {
    try {
        dispatch(createReportStart());
        const response = await APIClient.post('/reports/create', reportData);
        if (response && response.data && response.data.success) {
            dispatch(createReportSuccess(response.data));
            if (recallReports) recallReports();
            if (setShowListOfReports) {
                setShowListOfReports(false);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }

            if (recall) recall();
            dispatch(addNotification({ severity: 'success', message: t('alerts.reportCreateSuccess') }));
        } else {
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createReportFail);
    }
};

export const createReportAndPrint = ({ reportData }) => async (dispatch) => {
    try {
        dispatch(createReportStart());
        const response = await APIClient.post('/reports/create', reportData);
        if (response && response.data && response.data.success) {
            dispatch(createReportSuccess(response.data));
            return response.data.data;
        }
        dispatch(createReportFail(t('alerts.reportCreateFail')));
        dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        return null;
    } catch (error) {
        HandleAPIError(error, dispatch, createReportFail);
        return error;
    }
};
export const clearOneReport = () => async (dispatch) => {
    dispatch(createReportReset());
};

export const getAllReports = (data) => async (dispatch) => {
    try {
        dispatch(getAllReportsStart());
        const response = await APIClient.get('/reports', {
            params: {
                limit: data.limit,
                page: data.page,
                id: data.patientId,
                getMyReports: data.getMyReports,
                ...(data.filterAllReports === false && { toothNumber: data.toothNumber })
            }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllReportsSuccess(response.data));
        } else {
            dispatch(getAllReportsFail(t('alerts.reportFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllReportsFail);
    }
};

export const getOneReport = (reportId) => async (dispatch) => {
    try {
        dispatch(getOneReportStart());
        const response = await APIClient.get(`/reports/${reportId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOneReportSuccess(response.data));
        } else {
            dispatch(getOneReportFail(t('alerts.reportFetchOneError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOneReportFail);
    }
};

export const updateReport = ({ reportId, reportData, recallReports }) => async (dispatch) => {
    try {
        dispatch(updateReportStart());
        const response = await APIClient.patch(`/reports/${reportId}`, reportData);
        if (response && response.data) {
            dispatch(updateReportSuccess(response.data));
            if (recallReports) recallReports();
            dispatch(addNotification({ severity: 'success', message: t('alerts.reportUpdateSuccess') }));
        } else {
            dispatch(updateReportFail(t('alerts.reportUpdateFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updateReportFail);
    }
};

export const deleteReport = (reportId, recallReports) => async (dispatch) => {
    try {
        const response = await APIClient.patch(`/reports/${reportId}`, { isActive: false });
        if (response && response.data) {
            dispatch(deleteReportSuccess(reportId));
            dispatch(addNotification({ severity: 'success', message: t('alerts.reportDeleteSuccess') }));
            if (recallReports) recallReports();
        } else {
            dispatch(deleteReportFail(t('alerts.reportDeleteFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.reportDeleteFail') }));
        }
    } catch (error) {
        dispatch(deleteReportFail(error.message));
    }
};

export default clinicSlice.reducer;

import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';

import Transitions from 'components/extended/Transitions';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'store/language';

const LocalizationSection = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md')); // check for mobile screen

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const { i18n } = useTranslation();
    const { language } = useSelector((state) => state.language);

    const changeLang = (language) => {
        if (['sq', 'en'].includes(language)) {
            i18n.changeLanguage(language);
            dispatch(changeLanguage(language));
            localStorage.setItem('language', language); // Save to localStorage
            setOpen(false);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        dispatch(changeLanguage(language));
    }, [language]);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    [theme.breakpoints.down('md')]: {
                        ml: 1
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            width: matchesXs ? '40px' : '47px', // Adjust size for mobile
                            height: matchesXs ? '40px' : '48px', // Adjust size for mobile
                            border: '1px solid',
                            borderColor: theme.palette.secondary.light,
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            transition: 'all .2s ease-in-out',
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                borderColor: theme.palette.secondary.light,
                                background: theme.palette.secondary.light,
                                color: theme.palette.primary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <Typography variant="h5" sx={{ textTransform: 'uppercase', color: theme.palette.grey[600] }}>
                            {language}
                        </Typography>
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                        <Paper elevation={16}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        minWidth: 200,
                                        maxWidth: 280,
                                        bgcolor: theme.palette.background.paper,
                                        borderRadius: customization.borderRadius,
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 250
                                        }
                                    }}
                                >
                                    <ListItemButton selected={language === 'sq'} onClick={() => changeLang('sq')}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">Shqip</Typography>
                                                    <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                        (sq)
                                                    </Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                    <ListItemButton selected={language === 'en'} onClick={() => changeLang('en')}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">English</Typography>
                                                    <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                        (en)
                                                    </Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default LocalizationSection;

import { Link, useParams } from 'react-router-dom';
import { Button, Container, Grid, Typography, Box, Divider, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import teeth from 'assets/images/landing/teeth.jpg';
import { getAllServices, getAllServicesLandingPage } from 'store/services';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'components/extended/AnimateButton';

const cardStyle = {
    padding: '6px',
    backgroundColor: '#93BABB24',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    paddingBottom: '90%',
    position: 'relative',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    margin: 'auto'
};

const contentStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px'
};

const ServicesPage = ({ clinicData }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const serviceResponse = useSelector((state) => state.services.getAll);
    const loading = useSelector((state) => state.services.loading);
    const [page, setPage] = useState(1);
    const [services, setServices] = useState([]);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const limit = 3;
    const params = useParams();
    const clinicName = params.clinicName;
    const currentLanguage = i18n.language;
    useEffect(() => {
        dispatch(getAllServicesLandingPage(clinicName, { limit, page, isVisible: true }));
    }, [clinicName, dispatch, page]);

    useEffect(() => {
        if (serviceResponse?.data?.data?.docs) {
            setServices((prevServices) => [...prevServices, ...serviceResponse.data.data.docs]);
            setIsLoadingMore(false);
        }
    }, [serviceResponse]);

    const totalServices = serviceResponse?.data?.data?.meta?.total || 0;
    const showLoadMore = page * limit < totalServices;

    const loadMoreServices = () => {
        setIsLoadingMore(true);
        setPage((prevPage) => prevPage + 1);
    };

    // Retrieve landing data for the specified language
    const landingData = clinicData?.landingPage?.find((page) => page.language === currentLanguage) || {};

    return (
        <>
            <Divider />
            <Container maxWidth="lg" sx={{ pt: '60px' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} pb={2}>
                        <Typography
                            variant="body1"
                            color="primary"
                            align="center"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '15px', sm: '18px' },
                                color: theme.palette.secondary.main,
                                lineHeight: '24px'
                            }}
                        >
                            {landingData?.span2 || t('our_services')}
                        </Typography>

                        <Typography
                            variant="h2"
                            component="div"
                            align="center"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '23px', sm: '32px' },
                                lineHeight: { xs: '22px', sm: '34px' },
                                fontWeight: 500,
                                paddingBottom: 2
                            }}
                        >
                            {landingData?.header2 || t('explore_dental_services')}
                        </Typography>
                        <Typography
                            variant="body2"
                            align="center"
                            paragraph
                            sx={{
                                fontSize: { xs: '15px', sm: '16px' },
                                lineHeight: '34px',
                                fontWeight: 400,
                                color: theme.palette.text.primary
                            }}
                        >
                            {landingData?.paragraph2 || t('dental_services_description')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent={services.length > 2 ? 'flex-start' : 'center'} spacing={gridSpacing}>
                            {services.length === 0
                                ? [...Array(3)].map((_, index) => (
                                      <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`}>
                                          <Box sx={cardStyle}>
                                              <Box sx={contentStyle}>
                                                  <Skeleton variant="circular" width={60} height={60} sx={{ mb: 2 }} />
                                                  <Skeleton variant="text" width="60%" />
                                                  <Skeleton variant="text" width="80%" />
                                                  <Skeleton variant="text" width="40%" />
                                              </Box>
                                          </Box>
                                      </Grid>
                                  ))
                                : services.map((service, index) => (
                                      <Grid item xs={12} sm={6} md={4} key={service.id} mb={4}>
                                          <Box sx={cardStyle}>
                                              <Box sx={contentStyle}>
                                                  <Box
                                                      sx={{
                                                          width: '60px',
                                                          height: '60px',
                                                          borderRadius: '50%',
                                                          backgroundColor: '#ffffff',
                                                          display: 'flex',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                          mb: 2
                                                      }}
                                                  >
                                                      <img
                                                          src={service.photo || teeth}
                                                          alt="Service Icon"
                                                          onError={(e) => {
                                                              e.target.onerror = null;
                                                              e.target.src = teeth;
                                                          }}
                                                          style={{ width: '33px', color: '#555' }}
                                                      />
                                                  </Box>
                                                  <Typography sx={{ fontSize: '20px', fontWeight: 500, lineHeight: '34px', pb: 2 }}>
                                                      {currentLanguage === 'en' ? service.nameEn : service.nameSq}
                                                  </Typography>
                                                  <Typography
                                                      sx={{
                                                          fontSize: '12px',
                                                          fontWeight: 300,
                                                          color: '#666',
                                                          textAlign: 'center',
                                                          lineHeight: '20px'
                                                      }}
                                                  >
                                                      {currentLanguage === 'en' ? service.descriptionEn : service.descriptionSq}
                                                  </Typography>
                                              </Box>
                                          </Box>
                                      </Grid>
                                  ))}

                            {isLoadingMore &&
                                [...Array(3)].map((_, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`} mb={4}>
                                        <Box sx={cardStyle}>
                                            <Box sx={contentStyle}>
                                                <Skeleton variant="circular" width={60} height={60} sx={{ mb: 2 }} />
                                                <Skeleton variant="text" width="60%" />
                                                <Skeleton variant="text" width="80%" />
                                                <Skeleton variant="text" width="40%" />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                    {showLoadMore && (
                        <Grid item xs={12} sx={{ textAlign: 'center' }} pb={1}>
                            <AnimateButton>
                                <Button
                                    onClick={loadMoreServices}
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    sx={{ color: 'white' }}
                                >
                                    {t('landing_page.load_more')}
                                </Button>
                            </AnimateButton>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </>
    );
};

export default ServicesPage;

import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import axios from 'axios';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const doctorSlice = createSlice({
    name: 'doctors',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState },
        uploadPhoto: { ...initialState },
        deletePhoto: { ...initialState }
    },
    reducers: {
        createDoctorStart(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = true;
            state.create.success = false;
        },
        createDoctorSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createDoctorFail(state, action) {
            state.create.data = null;
            state.create.errorMessage = action.payload;
            state.create.error = true;
            state.create.loading = false;
            state.create.success = false;
        },
        createDoctorReset(state) {
            state.create.data = null;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = false;
        },
        getAllDoctorsStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllDoctorsSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllDoctorsFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllDoctorsReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneDoctorStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneDoctorSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneDoctorFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOneDoctorReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        updateDoctorStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateDoctorSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateDoctorFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updateDoctorReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        },
        deleteDoctorStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteDoctorSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteDoctorFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        },
        uploadPhotoStart(state) {
            state.uploadPhoto.data = null;
            state.uploadPhoto.error = false;
            state.uploadPhoto.errorMessage = null;
            state.uploadPhoto.loading = true;
            state.uploadPhoto.success = false;
        },
        uploadPhotoSuccess(state, action) {
            state.uploadPhoto.data = action.payload;
            state.uploadPhoto.error = false;
            state.uploadPhoto.errorMessage = null;
            state.uploadPhoto.loading = false;
            state.uploadPhoto.success = true;
        },
        uploadPhotoFail(state, action) {
            state.uploadPhoto.data = null;
            state.uploadPhoto.errorMessage = action.payload;
            state.uploadPhoto.error = true;
            state.uploadPhoto.loading = false;
            state.uploadPhoto.success = false;
        },
        uploadPhotoReset(state) {
            state.uploadPhoto.data = null;
            state.uploadPhoto.error = false;
            state.uploadPhoto.errorMessage = null;
            state.uploadPhoto.loading = false;
            state.uploadPhoto.success = false;
        },

        deletePhotoSuccess(state, action) {
            state.deletePhoto.data = action.payload;
            state.deletePhoto.error = false;
            state.deletePhoto.errorMessage = null;
            state.deletePhoto.loading = false;
            state.deletePhoto.success = true;
        },
        deletePhotoFail(state, action) {
            state.deletePhoto.data = null;
            state.deletePhoto.errorMessage = action.payload;
            state.deletePhoto.error = true;
            state.deletePhoto.loading = false;
            state.deletePhoto.success = false;
        }
    }
});

export const {
    createDoctorStart,
    createDoctorSuccess,
    createDoctorReset,
    createDoctorFail,
    getAllDoctorsFail,
    getAllDoctorsReset,
    getAllDoctorsStart,
    getAllDoctorsSuccess,
    updateDoctorStart,
    updateDoctorSuccess,
    updateDoctorFail,
    updateDoctorReset,
    deleteDoctorStart,
    deleteDoctorSuccess,
    deleteDoctorFail,
    getOneDoctorStart,
    getOneDoctorReset,
    getOneDoctorSuccess,
    getOneDoctorFail,
    uploadPhotoFail,
    uploadPhotoReset,
    uploadPhotoSuccess,
    uploadPhotoStart,
    deletePhotoSuccess,
    deletePhotoFail
} = doctorSlice.actions;

export const createDoctor = ({ doctorData }) => async (dispatch) => {
    try {
        dispatch(createDoctorStart());
        const response = await APIClient.post('/doctors/create', doctorData);
        if (response && response.data && response.data.success) {
            dispatch(createDoctorSuccess(response.data));
            return response.data.data;
        }
        dispatch(createDoctorFail(t('alerts.doctorCreateFail')));
        dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        return null;
    } catch (error) {
        HandleAPIError(error, dispatch, createDoctorFail);
        return error;
    }
};

export const getAllDoctors = (data) => async (dispatch) => {
    try {
        dispatch(getAllDoctorsStart());
        const response = await APIClient.get('/doctors', {
            params: { limit: data.limit, page: data.page, search: data.search, pagination: data.pagination }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllDoctorsSuccess(response.data));
        } else {
            dispatch(getAllDoctorsFail(t('alerts.doctorFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllDoctorsFail);
    }
};

export const getAllDoctorsLandingPage = (clinicName, data) => async (dispatch) => {
    try {
        dispatch(getAllDoctorsStart());
        const response = await APIClient.get(`/doctors/clinic/${clinicName}`, {
            params: { limit: data.limit, page: data.page, search: data.search, pagination: data.pagination }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllDoctorsSuccess(response.data));
        } else {
            dispatch(getAllDoctorsFail(t('alerts.doctorFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllDoctorsFail);
    }
};
export const clearAllDoctors = () => async (dispatch) => {
    dispatch(getAllDoctorsReset());
};

export const getOneDoctor = (doctorId) => async (dispatch) => {
    try {
        dispatch(getOneDoctorStart());
        const response = await APIClient.get(`/doctors/${doctorId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOneDoctorSuccess(response.data));
        } else {
            dispatch(getOneDoctorFail(t('alerts.doctorFetchOneError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getOneDoctorFail);
    }
};

export const clearOneDoctor = () => async (dispatch) => {
    dispatch(getOneDoctorReset());
};
export const updateDoctor = (payload, options) => async (dispatch) => {
    try {
        dispatch(updateDoctorStart());
        const { id, data } = payload;
        const response = await APIClient.patch(`/doctors/${id}`, data);
        if (response && response.data.data && response.data.success) {
            dispatch(updateDoctorSuccess(response.data.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.doctorUpdateSuccess') }));
            return response.data.data;
        }
        dispatch(updateDoctorFail(t('alerts.doctorUpdateFail')));
        dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        return null;
    } catch (error) {
        HandleAPIError(error, dispatch, updateDoctorFail);
        return error;
    }
};

export const deleteDoctor = (doctorId, recall) => async (dispatch) => {
    try {
        dispatch(deleteDoctorStart());
        const response = await APIClient.patch(`/doctors/${doctorId}`, { isActive: false });
        if (response.data && response.data.data) {
            dispatch(deleteDoctorSuccess(response.data.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.doctorDeleteSuccess') }));
            if (recall) recall();
        } else {
            dispatch(deleteDoctorFail(t('alerts.doctorDeleteFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.doctorDeleteFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteDoctorFail);
    }
};

export const uploadUserPhoto = (payload, options) => async (dispatch) => {
    const { recall, navigate, pathname } = options;
    try {
        dispatch(uploadPhotoStart());
        const { id, formData } = payload;
        const response = await APIClient.patch(`/doctors/upload/${id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        if (response.data && response.data.success) {
            const { data } = response.data;
            dispatch(uploadPhotoSuccess(data));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(uploadPhotoFail(t('alerts.photoUploadFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.photoUploadFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, uploadPhotoFail);
    }
};

export const deleteUserPhoto = (payload) => async (dispatch) => {
    try {
        dispatch(uploadPhotoStart());
        const { id, removeFile } = payload;
        const response = await APIClient.patch(`/doctors/upload/${id}`, removeFile);
        if (response.data && response.data.success) {
            const { data } = response.data;
            dispatch(deletePhotoSuccess(data));
        } else {
            dispatch(deletePhotoFail(t('alerts.photoDeleteFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.photoDeleteFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deletePhotoFail);
    }
};

export default doctorSlice.reducer;

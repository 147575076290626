import { useSelector } from 'react-redux';
import './i18n';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Routes from 'routes';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'components/extended/Snackbar';
import NotificationComponent from 'utils/notifications';
import FloatingWhatsApp from 'react-floating-whatsapp';
import logo from 'assets/images/login/logo-transparent-svg.svg';
import { useTranslation } from 'react-i18next';

const App = () => {
    const customization = useSelector((state) => state.customization);
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const clinicsResponse = useSelector((state) => state.clinics.getOne);
    const language = i18n.language;
    const clinicLogo = clinicsResponse?.data?.data?.landingPage?.find((page) => page.language === language)?.clinicLogo || logo;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <>
                        <Routes />
                        <Snackbar />
                        <NotificationComponent />
                        {location.pathname.startsWith('/home/') && (
                            <FloatingWhatsApp
                                phoneNumber={clinicsResponse?.data?.data?.number}
                                accountName={clinicsResponse?.data?.data?.name}
                                avatar={clinicLogo}
                                statusMessage={t('whatsapp.statusMessage')}
                                chatMessage={t('whatsapp.chatMessage')}
                                placeholder={t('whatsapp.placeholder')}
                            />
                        )}
                    </>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;

import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const meSlice = createSlice({
    name: 'me',
    initialState: {
        getMe: { ...initialState },
        update: { ...initialState }
    },
    reducers: {
        getMeStart(state) {
            state.getMe.data = null;
            state.getMe.error = false;
            state.getMe.errorMessage = null;
            state.getMe.loading = true;
            state.getMe.success = false;
        },
        getMeSuccess(state, action) {
            state.getMe.data = action.payload;
            state.getMe.error = false;
            state.getMe.errorMessage = null;
            state.getMe.loading = false;
            state.getMe.success = true;
        },
        getMeFail(state, action) {
            state.getMe.data = null;
            state.getMe.errorMessage = action.payload;
            state.getMe.error = true;
            state.getMe.loading = false;
            state.getMe.success = false;
        },
        getMeReset(state) {
            state.getMe.data = null;
            state.getMe.error = false;
            state.getMe.errorMessage = null;
            state.getMe.loading = false;
            state.getMe.success = false;
        },
        updateMeStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateMeSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateMeFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updateMeReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        }
    }
});

const { getMeFail, getMeStart, getMeSuccess, getMeReset, updateMeFail, updateMeStart, updateMeSuccess } = meSlice.actions;

export const getMe = () => async (dispatch) => {
    try {
        dispatch(getMeStart());
        const response = await APIClient.get('/me');
        if (response.data && response.data.data) {
            const data = response.data.data;
            dispatch(getMeSuccess(data));
        } else if (response && response.data && response.data.errors) {
            dispatch(getMeFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: t('alerts.fetchUserError') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getMeFail);
    }
};

export const updateMe = ({ userData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(updateMeStart());
        const response = await APIClient.patch('/me', userData);
        if (response && response.data) {
            dispatch(updateMeSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.userUpdateSuccess') }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(updateMeFail(response.data.errors));
            dispatch(addNotification({ severity: 'error', message: t('alerts.userUpdateFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updateMeFail);
    }
};
export const clearMe = () => async (dispatch) => {
    dispatch(getMeReset());
};

export default meSlice.reducer;

import { lazy } from 'react';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'components/Loadable';
import Landing from 'views/landing';

const AuthLogin = Loadable(lazy(() => import('views/auth/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/auth/authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('views/auth/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('views/auth/authentication/ResetPassword')));
const NoPagesFound = Loadable(lazy(() => import('views/dashboard/NoPagesFound')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <MinimalLayout />
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />
        },
        {
            path: '/reset-password',
            element: <ResetPassword />
        },
        {
            path: '/*',
            element: <NoPagesFound />
        },
        {
            path: '/home/:clinicName',
            element: <Landing />
        }
    ]
};

export default LoginRoutes;

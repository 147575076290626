import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import EventIcon from '@mui/icons-material/Event';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MedicationIcon from '@mui/icons-material/Medication';
import { LocalHospital } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const icons = {
    DashboardOutlinedIcon,
    PeopleOutlineIcon,
    EventIcon,
    LocalHospitalOutlinedIcon,
    EventNoteOutlinedIcon,
    MedicalServicesOutlinedIcon,
    PermIdentityIcon,
    MedicationIcon,
    LocalHospital
};

const superadminItems = {
    id: 'dashboard-group',
    type: 'group',
    children: [
        {
            id: 'clinics',
            title: t('clinics.title'),
            type: 'item',
            url: '/clinics',
            icon: icons.LocalHospitalOutlinedIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'profile',
                    title: t('account_settings.title'),
                    type: 'item',
                    url: '/profile',
                    icon: icons.PermIdentityIcon,
                    breadcrumbs: true
                },
                {
                    id: 'clinic/create',
                    title: t('clinics.title'),
                    type: 'item',
                    url: '/clinic/create',
                    icon: icons.LocalHospitalOutlinedIcon,
                    breadcrumbs: true
                },
                {
                    id: 'clinic/profile',
                    title: t('clinics.title'),
                    type: 'item',
                    url: '/clinic/profile/:id',
                    icon: icons.LocalHospitalOutlinedIcon,
                    breadcrumbs: true
                },
                {
                    id: 'clinic/edit',
                    title: t('clinics.title'),
                    type: 'item',
                    url: '/clinic/edit/:id',
                    icon: icons.LocalHospitalOutlinedIcon,
                    breadcrumbs: true
                }
            ]
        },

        {
            id: 'admins',
            title: t('admins.title'),
            type: 'item',
            url: '/admins',
            icon: icons.PeopleOutlineIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'admin/create',
                    title: t('admins.title'),
                    type: 'item',
                    url: '/admin/create',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                },
                {
                    id: 'admin/profile',
                    title: t('admins.title'),
                    type: 'item',
                    url: '/admin/profile/:id',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                },
                {
                    id: 'admin/edit',
                    title: t('admins.title'),
                    type: 'item',
                    url: '/admin/edit/:id',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

const adminItems = {
    id: 'dashboard-group',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: t('dashboard.title'),
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlinedIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'profile',
                    title: t('account_settings.title'),
                    type: 'item',
                    url: '/profile',
                    icon: icons.PermIdentityIcon,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'calendar',
            title: t('calendar.title'),
            type: 'item',
            url: '/calendar',
            icon: icons.EventNoteOutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'patients',
            title: t('patients.title'),
            type: 'item',
            url: '/patients',
            icon: icons.PeopleOutlineIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'patient/create',
                    title: t('patients.title'),
                    type: 'item',
                    url: '/patient/create',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                },
                {
                    id: 'patient/profile-info',
                    title: t('patients.title'),
                    type: 'item',
                    url: '/patient/profile-info/:id',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                },
                {
                    id: 'patient/profile',
                    title: t('patients.title'),
                    type: 'item',
                    url: '/patient/profile/:id',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                },
                {
                    id: 'patient/edit',
                    title: t('patients.title'),
                    type: 'item',
                    url: '/patient/edit/:id',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                }
            ]
        },

        {
            id: 'appointments',
            title: t('appointments.title'),
            type: 'item',
            url: '/appointments',
            icon: icons.EventIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'appointment/create',
                    title: t('appointments.title'),
                    type: 'item',
                    url: '/appointment/create',
                    icon: icons.EventIcon,
                    breadcrumbs: true
                },
                {
                    id: 'appointment/profile',
                    title: t('appointments.title'),
                    type: 'item',
                    url: '/appointment/profile/:id',
                    icon: icons.EventIcon,
                    breadcrumbs: true
                },
                {
                    id: 'appointment/edit',
                    title: t('appointments.title'),
                    type: 'item',
                    url: '/appointment/edit/:id',
                    icon: icons.EventIcon,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'services',
            title: t('services.title'),
            type: 'item',
            url: '/services',
            icon: icons.MedicationIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'service/create',
                    title: t('services.title'),
                    type: 'item',
                    url: '/service/create',
                    icon: icons.MedicationIcon,
                    breadcrumbs: true
                },
                {
                    id: 'service/profile',
                    title: t('services.title'),
                    type: 'item',
                    url: '/specialization/profile/:id',
                    icon: icons.MedicationIcon,
                    breadcrumbs: true
                },
                {
                    id: 'service/edit',
                    title: t('services.title'),
                    type: 'item',
                    url: '/pecialization/edit/:id',
                    icon: icons.MedicationIcon,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'specializations',
            title: t('specializations.title'),
            type: 'item',
            url: '/specializations',
            icon: icons.MedicalServicesOutlinedIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'specialization/create',
                    title: t('specializations.title'),
                    type: 'item',
                    url: '/specialization/create',
                    icon: icons.MedicalServicesOutlinedIcon,
                    breadcrumbs: true
                },
                {
                    id: 'specialization/profile',
                    title: t('specializations.title'),
                    type: 'item',
                    url: '/specialization/profile/:id',
                    icon: icons.MedicalServicesOutlinedIcon,
                    breadcrumbs: true
                },
                {
                    id: 'specialization/edit',
                    title: t('specializations.title'),
                    type: 'item',
                    url: '/specialization/edit/:id',
                    icon: icons.MedicalServicesOutlinedIcon,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'doctors',
            title: t('doctors.title'),
            type: 'item',
            url: '/doctors',
            icon: icons.LocalHospitalOutlinedIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'doctor/create',
                    title: t('doctors.title'),
                    type: 'item',
                    url: '/doctor/create',
                    icon: icons.LocalHospitalOutlinedIcon,
                    breadcrumbs: true
                },
                {
                    id: 'doctor/profile',
                    title: t('doctors.title'),
                    type: 'item',
                    url: '/doctor/profile/:id',
                    icon: icons.LocalHospitalOutlinedIcon,
                    breadcrumbs: true
                },
                {
                    id: 'doctor/edit',
                    title: t('doctors.title'),
                    type: 'item',
                    url: '/doctor/edit/:id',
                    icon: icons.LocalHospitalOutlinedIcon,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

const doctorItems = {
    id: 'dashboard-group',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: t('dashboard.title'),
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlinedIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'profile',
                    title: t('account_settings.title'),
                    type: 'item',
                    url: '/profile',
                    icon: icons.PermIdentityIcon,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'calendar',
            title: t('calendar.title'),
            type: 'item',
            url: '/calendar',
            icon: icons.EventNoteOutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'patients',
            title: t('patients.title'),
            type: 'item',
            url: '/patients',
            icon: icons.PeopleOutlineIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'patient/create',
                    title: t('patients.title'),
                    type: 'item',
                    url: '/patient/create',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                },
                {
                    id: 'patient/profile-info',
                    title: t('patients.title'),
                    type: 'item',
                    url: '/patient/profile-info/:id',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                },
                {
                    id: 'patient/profile',
                    title: t('patients.title'),
                    type: 'item',
                    url: '/patient/profile/:id',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                },
                {
                    id: 'patient/edit',
                    title: t('patients.title'),
                    type: 'item',
                    url: '/patient/edit/:id',
                    icon: icons.PeopleOutlineIcon,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'appointments',
            title: t('appointments.title'),
            type: 'item',
            url: '/appointments',
            icon: icons.EventIcon,
            breadcrumbs: true
        },
        {
            id: 'services',
            title: t('services.title'),
            type: 'item',
            url: '/services',
            icon: icons.MedicationIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'service/create',
                    title: t('services.title'),
                    type: 'item',
                    url: '/service/create',
                    icon: icons.MedicationIcon,
                    breadcrumbs: true
                },
                {
                    id: 'service/profile',
                    title: t('services.title'),
                    type: 'item',
                    url: '/specialization/profile/:id',
                    icon: icons.MedicationIcon,
                    breadcrumbs: true
                },
                {
                    id: 'service/edit',
                    title: t('services.title'),
                    type: 'item',
                    url: '/specialization/edit/:id',
                    icon: icons.MedicationIcon,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

const menuItem = {
    superadmin: [superadminItems],
    admin: [adminItems],
    doctor: [doctorItems]
};

export default menuItem;

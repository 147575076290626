import { useTranslation } from 'react-i18next';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import landingImage from 'assets/images/landing/landingImage.svg';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import Loader from 'components/Loader';

const HeaderImage = styled('img')(({ theme }) => ({
    width: '100%',
    height: 'auto', // Maintain aspect ratio
    minHeight: '350px', // Set minimum height for the image
    objectFit: 'cover', // Prevent stretching while maintaining aspect ratio
    borderRadius: '20px',
    // boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Soft shadow for the image
    [theme.breakpoints.down('sm')]: {
        width: '100%', // Make the image full width on small screens
        height: 'auto' // Maintain aspect ratio on small screens
    }
}));

const HighlightedText = styled('span')(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontWeight: 500
}));

const HeaderPage = ({ clinicData }) => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const landingData = clinicData?.landingPage?.find((page) => page.language === currentLanguage) || {};

    // Track image load state
    const [imageLoaded, setImageLoaded] = useState(false);

    // Image onLoad handler
    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <>
            {!imageLoaded && <Loader />}
            <Container>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={gridSpacing}
                    sx={{ mt: { md: 7, lg: 10 }, mb: { lg: 7 } }}
                >
                    {/* Image Grid */}
                    <Grid
                        item
                        xs={12} // Full width on small screens
                        sm={12}
                        md={6} // // Half width on larger screens
                        sx={{ display: 'flex', justifyContent: 'center', mt: { xs: 12, md: 3 }, pr: { sm: 2 } }}
                    >
                        <Box sx={{ textAlign: 'center', position: 'relative' }}>
                            <HeaderImage
                                src={landingData.clinicPhoto || landingImage}
                                alt="Landing"
                                onLoad={handleImageLoad} // Trigger onLoad event
                            />
                        </Box>
                    </Grid>

                    {/* Text Grid */}
                    <Grid
                        item
                        xs={12} // Full width on small screens
                        sm={12}
                        md={6} // Half width on larger screens
                        sx={{ pr: { sm: 5 }, mt: { xs: 2, sm: 0 } }}
                    >
                        <Grid
                            container
                            spacing={gridSpacing}
                            sx={{
                                [theme.breakpoints.down('lg')]: { pr: 0, textAlign: 'center' },
                                mt: { xs: 2, sm: 0 }
                            }}
                        >
                            <Grid item xs={12}>
                                <motion.div
                                    initial={{ opacity: 0, translateX: 550 }}
                                    animate={{ opacity: 1, translateX: 0 }}
                                    transition={{ type: 'spring', stiffness: 150, damping: 30 }}
                                >
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            fontSize: { xs: '23px', sm: '20px', md: '32px' },
                                            fontWeight: 500,
                                            lineHeight: 1.4
                                        }}
                                    >
                                        <HighlightedText>{landingData?.header1 || 'Default Header'}</HighlightedText>
                                    </Typography>
                                </motion.div>
                            </Grid>
                            <Grid item xs={12}>
                                <motion.div
                                    initial={{ opacity: 0, translateX: 550 }}
                                    animate={{ opacity: 1, translateX: 0 }}
                                    transition={{ type: 'spring', stiffness: 150, damping: 30, delay: 0.2 }}
                                >
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        color="inherit"
                                        sx={{
                                            fontSize: { xs: '15px', md: '1rem' },
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            color: theme.palette.text.primary
                                        }}
                                    >
                                        {landingData?.paragraph1 || 'Default paragraph text here.'}
                                    </Typography>
                                </motion.div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default HeaderPage;

import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Header from './Header';
import Services from './Services';
import AppBar from 'ui-component/extended/AppBar';
import StaffPage from './Staff';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getOneClinic, getOneClinicLandingPage } from 'store/clinics';
import ContactPage from './ContactPage';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';

const HeaderWrapper = styled('div')(({ theme }) => ({
    paddingTop: 30,
    overflowX: 'hidden',
    overflowY: 'clip',
    [theme.breakpoints.down('md')]: {
        paddingTop: 42
    }
}));

const SecondWrapper = styled('div')(({ theme }) => ({
    paddingTop: 45,
    [theme.breakpoints.down('md')]: {
        paddingTop: 45
    }
}));

// const LoadingSpinner = () => (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//         <div
//             className="spinner"
//             style={{
//                 width: '50px',
//                 height: '50px',
//                 border: '5px solid #f3f3f3',
//                 borderTop: '5px solid #3498db',
//                 borderRadius: '50%',
//                 animation: 'spin 2s linear infinite'
//             }}
//         />
//     </div>
// );

const Landing = () => {
    const dispatch = useDispatch();
    const servicesRef = useRef(null);
    const staffRef = useRef(null);
    const contactRef = useRef(null);
    const clinicsResponse = useSelector((state) => state.clinics.getOne);
    const params = useParams();
    const [clinicData, setClinicData] = useState({});
    const [loading, setLoading] = useState(true);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const clinicName = params.clinicName;

    useEffect(() => {
        if (clinicName) {
            dispatch(getOneClinicLandingPage(clinicName));
        }
    }, [clinicName, dispatch]);

    useEffect(() => {
        if (clinicsResponse) {
            switch (true) {
                case clinicsResponse.loading:
                    setLoading(true);
                    break;
                case clinicsResponse.success:
                    setClinicData(clinicsResponse.data.data);
                    setLoading(false);
                    break;
                case clinicsResponse.error:
                    setLoading(false);
                    break;
                default:
                    break;
            }
        }
    }, [clinicsResponse]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <HeaderWrapper id="home">
                        <AppBar
                            clinicData={clinicData}
                            scrollToSection={scrollToSection}
                            sections={{ servicesRef, staffRef, contactRef }}
                        />
                        <Header clinicData={clinicData} />
                    </HeaderWrapper>
                    <SecondWrapper ref={servicesRef}>
                        <Services clinicData={clinicData} />
                    </SecondWrapper>
                    <SecondWrapper ref={staffRef}>
                        <StaffPage clinicData={clinicData} />
                    </SecondWrapper>
                    <SecondWrapper ref={contactRef}>
                        <ContactPage clinicData={clinicData} />
                    </SecondWrapper>
                    <Footer clinicData={clinicData} scrollToSection={scrollToSection} sections={{ servicesRef, staffRef, contactRef }} />
                </>
            )}
        </>
    );
};

export default Landing;

import { Button, Container, Grid, Typography, Box, Divider, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllDoctors, getAllDoctorsLandingPage } from 'store/doctors';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'components/extended/AnimateButton';
import imagePlaceholder from 'assets/images/landing/imagePlaceholder.png';
import { useParams } from 'react-router-dom';

const cardStyle = {
    borderRadius: '0px',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    minHeight: '300px',
    margin: 'auto'
};

const imageContainerStyle = {
    width: '100%',
    height: '300px',
    overflow: 'hidden',
    position: 'relative',
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center'
};

const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '0px'
};

const textContainerStyle = {
    padding: '16px 16px',
    width: '100%',
    borderTop: '1px solid #e0e0e0'
};

const nameStyle = {
    fontSize: '16px',
    fontWeight: 600,
    color: '#333',
    marginBottom: '4px'
};

const titleStyle = {
    fontSize: '14px',
    color: '#666'
};

const StaffPage = ({ clinicData }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const doctorResponse = useSelector((state) => state.doctors.getAll);
    const loading = useSelector((state) => state.doctors.loading);
    const [page, setPage] = useState(1);
    const [doctors, setDoctors] = useState([]);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const limit = 3;
    const params = useParams();
    const clinicName = params.clinicName;

    useEffect(() => {
        dispatch(getAllDoctorsLandingPage(clinicName, { limit, page }));
    }, [clinicName, dispatch, page]);

    useEffect(() => {
        if (doctorResponse?.data?.data?.docs) {
            setDoctors((prevDoctors) => [...prevDoctors, ...doctorResponse.data.data.docs]);
            setIsLoadingMore(false);
        }
    }, [doctorResponse]);

    const totalDoctors = doctorResponse?.data?.data?.meta?.total || 0;
    const showLoadMore = page * limit < totalDoctors;

    const loadMoreDoctors = () => {
        setIsLoadingMore(true);
        setPage((prevPage) => prevPage + 1);
    };

    // Set the current language directly to 'en'
    const currentLanguage = i18n.language;

    // Retrieve landing data for the specified language
    const landingData = clinicData?.landingPage?.find((page) => page.language === currentLanguage) || {};

    return (
        <>
            <Divider />
            <Container sx={{ pt: '60px' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} pb={2}>
                        <Typography
                            variant="body1"
                            color="primary"
                            align="center"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '15px', sm: '18px' },
                                color: theme.palette.secondary.main,
                                lineHeight: '24px'
                            }}
                        >
                            {landingData?.span3 || t('our_staff')}
                        </Typography>
                        <Typography
                            variant="h2"
                            component="div"
                            align="center"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '23px', sm: '32px' },
                                lineHeight: { xs: '22px', sm: '34px' },
                                fontWeight: 500,
                                paddingBottom: 2
                            }}
                        >
                            {landingData?.header3 || t('meet_our_dedicated_team')}
                        </Typography>
                        <Typography
                            variant="body2"
                            align="center"
                            paragraph
                            sx={{
                                fontSize: { xs: '15px', sm: '16px' },
                                lineHeight: '34px',
                                fontWeight: 400,
                                color: theme.palette.text.primary
                            }}
                        >
                            {landingData?.paragraph3 || t('staff_description')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} pb={1}>
                        <Grid container justifyContent={doctors.length > 2 ? 'flex-start' : 'center'} spacing={gridSpacing}>
                            {doctors.length === 0
                                ? [...Array(3)].map((_, index) => (
                                      <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`} mb={4}>
                                          <Box sx={cardStyle}>
                                              <Box sx={imageContainerStyle}>
                                                  <Skeleton variant="rectangular" width="100%" height="100%" />
                                              </Box>
                                              <Box sx={textContainerStyle}>
                                                  <Skeleton variant="text" width="60%" />
                                                  <Skeleton variant="text" width="40%" />
                                              </Box>
                                          </Box>
                                      </Grid>
                                  ))
                                : doctors.map((doctor) => (
                                      <Grid item xs={12} sm={6} md={4} key={doctor.id} mb={3}>
                                          <Box sx={cardStyle}>
                                              <Box sx={imageContainerStyle}>
                                                  <img
                                                      src={doctor?.photo?.fullPath || imagePlaceholder}
                                                      alt={`${doctor?.user?.firstName} ${doctor?.user?.lastName}`}
                                                      style={imageStyle}
                                                  />
                                              </Box>
                                              <Box sx={textContainerStyle}>
                                                  <Typography sx={nameStyle}>
                                                      {doctor?.user?.firstName} {doctor?.user?.lastName}
                                                  </Typography>
                                                  <Typography sx={titleStyle}>
                                                      {currentLanguage === 'en'
                                                          ? doctor?.specialization?.titleEn
                                                          : doctor?.specialization?.titleSq}
                                                  </Typography>
                                              </Box>
                                          </Box>
                                      </Grid>
                                  ))}

                            {isLoadingMore &&
                                [...Array(3)].map((_, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`}>
                                        <Box sx={cardStyle}>
                                            <Box sx={imageContainerStyle}>
                                                <Skeleton variant="rectangular" width="100%" height="100%" />
                                            </Box>
                                            <Box sx={textContainerStyle}>
                                                <Skeleton variant="text" width="60%" />
                                                <Skeleton variant="text" width="40%" />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>

                    {showLoadMore && (
                        <Grid item xs={12} sx={{ textAlign: 'center' }} pb={1}>
                            <AnimateButton>
                                <Button
                                    onClick={loadMoreDoctors}
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    sx={{ color: 'white' }}
                                >
                                    {t('landing_page.load_more')}
                                </Button>
                            </AnimateButton>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </>
    );
};

export default StaffPage;

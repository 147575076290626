import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, useMediaQuery } from '@mui/material';
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import { IconMenu2 } from '@tabler/icons';
import LocalizationSection from './LocalizationSection';
import navigation from 'menu-items';
import PageTitleComponent from 'components/extended/PageTitleComponent';

const Header = ({ handleLeftDrawerToggle, leftDrawerOpened, displaySearch }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            {/* logo & toggler button */}
            <Box
                component="nav"
                sx={{
                    display: 'flex',
                    flexShrink: { md: 0 },
                    width: matchUpMd ? 260 : 'auto',
                    backgroundColor: leftDrawerOpened ? theme.palette.common.white : theme.palette.grey[50],
                    padding: '24px'
                }}
                aria-label="mailbox folders"
                // sx={
                //     {
                //         // width: { xs: 80, md: 305 },
                //         // display: 'flex',
                //         // // [theme.breakpoints.down('md')]: {
                //         // //     // width: 'auto !imporant'
                //         // //     width: 330
                //         // // },
                //         // backgroundColor: leftDrawerOpened ? theme.palette.common.white : theme.palette.grey[50],
                //         // padding: '24px'
                //     }
                // }
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', mt: { xs: 2, md: 0 } }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            backgroundColor: 'inherit',
                            color: theme.palette.secondary.main,
                            '&:hover': {
                                background: theme.palette.secondary.light + 80,
                                color: theme.palette.secondary.main
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    backgroundColor: theme.palette.grey[50],

                    mt: 3,
                    alignItems: 'center'
                }}
            >
                <Box>
                    <PageTitleComponent navigation={navigation} icon title />
                </Box>

                <Box sx={{ display: 'flex' }}>
                    {displaySearch && (
                        <Box mr={4}>
                            <SearchSection />
                        </Box>
                    )}

                    <Box mr={5} sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <LocalizationSection />
                    </Box>
                    <Box>
                        <ProfileSection />
                    </Box>
                </Box>
            </Box>

            {/* mobile header */}
            {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;

import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import APIClient from 'services/ApiClient';
import { addNotification } from 'store/notification';
import HandleAPIError from 'utils/Error';

const initialState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false
};

const calendarSlice = createSlice({
    name: 'appointments',
    initialState: {
        getAll: { ...initialState },
        getOne: { ...initialState },
        create: { ...initialState },
        update: { ...initialState },
        delete: { ...initialState }
    },
    reducers: {
        createAppointmentStart(state) {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        createAppointmentSuccess(state, action) {
            state.create.data = action.payload;
            state.create.error = false;
            state.create.errorMessage = null;
            state.create.loading = false;
            state.create.success = true;
        },
        createAppointmentFail(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
        createAppointmentReset(state) {
            state.loading = false;
            state.error = null;
            state.success = false;
        },
        getAllAppointmentsStart(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = true;
            state.getAll.success = false;
        },
        getAllAppointmentsSuccess(state, action) {
            state.getAll.data = action.payload;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = true;
        },
        getAllAppointmentsFail(state, action) {
            state.getAll.data = null;
            state.getAll.errorMessage = action.payload;
            state.getAll.error = true;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getAllAppointmentsReset(state) {
            state.getAll.data = null;
            state.getAll.error = false;
            state.getAll.errorMessage = null;
            state.getAll.loading = false;
            state.getAll.success = false;
        },
        getOneAppointmentStart(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = true;
            state.getOne.success = false;
        },
        getOneAppointmentSuccess(state, action) {
            state.getOne.data = action.payload;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = true;
        },
        getOneAppointmentFail(state, action) {
            state.getOne.data = null;
            state.getOne.errorMessage = action.payload;
            state.getOne.error = true;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        getOneAppointmentReset(state) {
            state.getOne.data = null;
            state.getOne.error = false;
            state.getOne.errorMessage = null;
            state.getOne.loading = false;
            state.getOne.success = false;
        },
        updateAppointmentStart(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = true;
            state.update.success = false;
        },
        updateAppointmentSuccess(state, action) {
            state.update.data = action.payload;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = true;
        },
        updateAppointmentFail(state, action) {
            state.update.data = null;
            state.update.errorMessage = action.payload;
            state.update.error = true;
            state.update.loading = false;
            state.update.success = false;
        },
        updateAppointmentReset(state) {
            state.update.data = null;
            state.update.error = false;
            state.update.errorMessage = null;
            state.update.loading = false;
            state.update.success = false;
        },
        deleteAppointmentStart(state) {
            state.delete.data = null;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = true;
            state.delete.success = false;
        },
        deleteAppointmentSuccess(state, action) {
            state.delete.data = action.payload;
            state.delete.error = false;
            state.delete.errorMessage = null;
            state.delete.loading = false;
            state.delete.success = true;
        },
        deleteAppointmentFail(state, action) {
            state.delete.data = null;
            state.delete.errorMessage = action.payload;
            state.delete.error = true;
            state.delete.loading = false;
            state.delete.success = false;
        }
    }
});

export const {
    createAppointmentStart,
    createAppointmentSuccess,
    createAppointmentReset,
    createAppointmentFail,
    getAllAppointmentsStart,
    getAllAppointmentsSuccess,
    getAllAppointmentsFail,
    getAllAppointmentsReset,
    updateAppointmentFail,
    updateAppointmentReset,
    updateAppointmentStart,
    updateAppointmentSuccess,
    getOneAppointmentFail,
    getOneAppointmentReset,
    getOneAppointmentStart,
    getOneAppointmentSuccess,
    deleteAppointmentFail,
    deleteAppointmentStart,
    deleteAppointmentSuccess
} = calendarSlice.actions;

export const createAppointment = ({ appointmentData, recall, navigate, pathname }) => async (dispatch) => {
    try {
        dispatch(createAppointmentStart());
        const response = await APIClient.post('/appointments/create', appointmentData);
        if (response && response.data && response.data.success) {
            dispatch(createAppointmentSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.appointmentCreateSuccess') }));
            if (recall) recall();
            navigate(pathname);
        } else {
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, createAppointmentFail);
    }
};
export const clearAllAppointments = () => async (dispatch) => {
    dispatch(getAllAppointmentsReset());
};
export const getAllAppointments = (data) => async (dispatch) => {
    try {
        dispatch(getAllAppointmentsStart());
        const response = await APIClient.get('/appointments', {
            params: {
                limit: data.limit,
                page: data.page,
                doctorId: data.doctorId,
                day: data.day,
                search: data.search,
                pagination: data.pagination,
                futureAppointments: data.futureAppointments,
                pastAppointments: data.pastAppointments
            }
        });
        if (response && response.data && response.data.success) {
            dispatch(getAllAppointmentsSuccess(response.data));
        } else {
            dispatch(getAllAppointmentsFail(t('alerts.appointmentFetchAllError')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, getAllAppointmentsFail);
    }
};

export const updateAppointment = (payload, options) => async (dispatch) => {
    try {
        dispatch(updateAppointmentStart());
        const { id, appointmentData } = payload;
        const { recall, navigate, pathname, state } = options;
        const response = await APIClient.patch(`/appointments/${id}`, appointmentData);
        if (response && response.data) {
            dispatch(updateAppointmentSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.appointmentUpdateSuccess') }));
            if (recall) recall();
            navigate(pathname, { state });
        } else {
            dispatch(updateAppointmentFail(t('alerts.appointmentUpdateFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, updateAppointmentFail);
    }
};
export const clearUpdateAppointment = () => async (dispatch) => {
    dispatch(updateAppointmentReset());
};

export const getOneAppointment = (appointmentId) => async (dispatch) => {
    try {
        dispatch(getOneAppointmentStart());
        const response = await APIClient.get(`/appointments/${appointmentId}`);
        if (response && response.data && response.data.success) {
            dispatch(getOneAppointmentSuccess(response.data));
            return response.data;
        }
        dispatch(getOneAppointmentFail(t('alerts.appointmentFetchOneError')));
        dispatch(addNotification({ severity: 'error', message: t('alerts.unexpectedServerResponse') }));
        return null;
    } catch (error) {
        HandleAPIError(error, dispatch, getOneAppointmentFail);
        return error;
    }
};
export const clearOneAppointment = () => async (dispatch) => {
    dispatch(getOneAppointmentReset());
};
export const deleteAppointment = (id, recall) => async (dispatch) => {
    try {
        dispatch(deleteAppointmentStart());
        const response = await APIClient.patch(`/appointments/${id}`, { isActive: false });
        if (response.data) {
            dispatch(deleteAppointmentSuccess(response.data));
            dispatch(addNotification({ severity: 'success', message: t('alerts.appointmentDeleteSuccess') }));
            if (recall) recall();
        } else {
            dispatch(deleteAppointmentFail(t('alerts.appointmentDeleteFail')));
            dispatch(addNotification({ severity: 'error', message: t('alerts.appointmentDeleteFail') }));
        }
    } catch (error) {
        HandleAPIError(error, dispatch, deleteAppointmentFail);
    }
};

export default calendarSlice.reducer;
